.app {
  font-family: Calibri, 'Gill Sans', 'Gill Sans MT', 'Trebuchet MS', sans-serif;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

.navbar {
  --bs-navbar-brand-margin-end: 0em !important;
}

#app-logo {
  width: 10em;
}

#products-list a {
  height: 25rem;
}

#products-list, #product-carousel {
  width: 99%;
}

.card-img-top {
  height: 90%;
}

.nav-item a:hover, .nav-item button:hover{
  border-bottom: 2px solid #000 !important;
}

#gallery-img {
  width: 250px;
  height: 250px;
}

@media (max-width: 600px) {
  #contac-info span {
    display: block;
    font-size: 12px;
  }
}